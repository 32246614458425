
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "individual-client-hospital-information",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  data() {
    return {
      hospitalDetails: {
        hospitalName: "",
        hospitalAddress: "",
        hospitalPhoneNumber: ""
        // password: "",
      }
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);

    const hospitalDetailsValidator = Yup.object().shape({
      hospitalName: Yup.string().required().label("Hospital name"),
      hospitalAddress: Yup.string().required().label("Hospital address"),
      hospitalPhoneNumber: Yup.number()
        .required()
        .min(11)
        .label("Hospital phone number")
      // password: Yup.string()
      //   .min(8)
      //   .required()
      //   .label("Password"),
    });

    const updateHospitalInformation = (values) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        // Send hospital information update request
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.patch(variables.PROFILE_HOSPITAL_INFORMATION, values)
            .then((response) => {
              variables.toastAlert(response.data.message, "success");
            })
            .catch(function (error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Hospital Information", ["Profile"]);
    });

    return {
      submitButton1,
      updateHospitalInformation,
      hospitalDetailsValidator
    };
  },
  methods: {
    getHospitalInformation() {
      //Fetch individual client profile hospital infomation
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.PROFILE_HOSPITAL_INFORMATION)
          .then(({ data }) => {
            //Assign data to form fields
            this.hospitalDetails.hospitalName = data.data.hospitalName;
            this.hospitalDetails.hospitalAddress = data.data.hospitalAddress;
            this.hospitalDetails.hospitalPhoneNumber =
              data.data.hospitalPhoneNumber;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    }
  },
  mounted() {
    // Get user hospital information on page load
    this.getHospitalInformation();
  },
  created() {
    //Set page title
    document.title = "Hospital Information | " + process.env.VUE_APP_TITLE;
  }
});
